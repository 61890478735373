.cd-benefit {
    max-width: 560px;
    padding-left: 38px;

    position: relative;
}
.cd-benefit__num {
    position: absolute;
    top: 1px;
    left: 0;

    font-family: $font-family-solid;
    font-size: 12px;
    line-height: 150%;
    font-weight: 500;
    color: $color-orange-dark;
    letter-spacing: .05em;

    @media (min-width: $small-size) {
        top: 3px;
    }
}
.cd-benefit__name {
    margin-bottom: 10px;

    color: #222222;
}
.cd-benefit__text {
    @media (min-width: $small-size) {
        max-width: 500px;
    }
    @media (min-width: $medium-size) {
        max-width: none;
    }
}

.cd-benefit__list {
    margin-top: 16px;
    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}
.cd-benefit__list-item {
    padding-left: 18px;

    position: relative; 

    line-height: 160%;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &::before {
        content: '';

        width: 8px;
        height: 8px;

        position: absolute;
        top: 8px;
        left: 0;

        background-color: $color-laguna-dark;
        border-radius: 50%;
    }
}
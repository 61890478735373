@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

@import '../benefits/benefits-card.scss';

.benefits-new {
    overflow: hidden;
}

.benefits-new__container {
    padding-bottom: 0;

    @media (min-width: $medium-size) {
        margin-top: 150px;
    }
}

.benefits-new__heading {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    @media (min-width: $small-mid-size) {
        margin-bottom: 40px;
    }
}

.benefits-new__grid {
    display: grid;
    grid-row-gap: 30px;

    @media (min-width: $medium-size) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 32px;
    }

    @media (min-width: $large-size) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.benefits-new__item {
    &:nth-child(4),
    &:nth-child(5) {
        order: 2;

        @media (min-width: $medium-size) {
            order: auto;
        }
    }
    &_mosaic {
        justify-self: end;
        order: 1;

        width: 100%;
        max-width: 600px;

        @media (min-width: $medium-size) {
            order: auto;
            grid-row: 1 / 3 span;
            grid-column: 1;
        }

        @media (min-width: $large-size) {
            grid-row: 1 / 3 span;
        }
    }
    &_media {
        justify-self: end;
        order: 3;

        width: 100%;
        max-width: 600px;

        @media (min-width: $medium-size) {
            order: auto;
            grid-row: 4 / 5 span;
            grid-column: 2;
        }

        @media (min-width: $large-size) {
            grid-row: auto;
            grid-column: auto;
        }
    }

    .cd-benefit {
        @media (min-width: $medium-size) {
            padding-left: 0;
        }
    }

    .cd-benefit__num {
        @media (min-width: $medium-size) {
            margin-bottom: 10px;
            
            position: static;
        }
    }
}
.benefits-new__media {
    &_video {
        @include aspect-ratio-w-pseudo(3, 2);
    }
}

.benefits-new__mosaic {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    position: relative;

    @include aspect-ratio-w-pseudo(10, 11);

    @media (min-width: $smallest-size) {
        @include aspect-ratio-w-pseudo(20, 21);
    }
    @media (min-width: $small-size) {
        @include aspect-ratio-w-pseudo(1, 1);
    }
    @media (min-width: $medium-size) {
        @include aspect-ratio-w-pseudo(4, 5);
        margin: 0;
    }
}
.benefits-new__img {
    width: 50%;

    @include img-wrap;

    position: absolute;

    &:nth-child(1) {
        top: 0;
        left: 0;

        @include aspect-ratio-w-pseudo(1, 1);

        @media (min-width: $small-size) {
            width: 45%;
        }

        @media (min-width: $medium-size) {
            top: auto;
            left: auto;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }
    &:nth-child(2) {
        top: 24px;
        right: 0;
        z-index: 1;

        @include aspect-ratio-w-pseudo(1, 1.5);

        @media (min-width: $medium-size) {
            width: 66%;
            top: 0;
            right: auto;
            left: 0;
            z-index: 0;
        }
    }
    &:nth-child(3) {
        bottom: 0;
        left: 44px;

        @include aspect-ratio-w-pseudo(1, 1);
        
        @media (min-width: $medium-size) {
            display: none;

            left: 100px;
        }
    }
}